import {
  CoolOffPeriodType,
  CurrencyCode,
  ExoticType,
  InterestPayoutPeriodType,
  InternalAccountHolderType,
  InternalTaxWrapper,
  ProductType,
} from '@b7hio/api-lib/src/ops-portal';
import {
  requiredWhen,
  tsEnum,
} from '@b7hio/core-lib/src/form/superstruct.helper';
import {
  minSize,
  notEmpty,
  stringNumber,
  stringPercentageNumber,
} from '@b7hio/core-lib/src/form/validators';
import { RefObject } from 'react';
import {
  any,
  array,
  assign,
  coerce,
  nullable,
  object,
  optional,
  refine,
  size,
  string,
  enums,
} from 'superstruct';

const bankUidString = () =>
  coerce(
    string(),
    object({
      label: string(),
      value: string(),
    }),
    (value) => value?.value
  );

// const dateRegex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const UkModulusChecking = require('uk-modulus-checking');

// This should match BankProductRequestWithRateAndFeeDetail
export const ProductValidation = object({
  externalId: notEmpty(string()),
  name: notEmpty(string()),
  productType: tsEnum(ProductType),
  exoticType: tsEnum(ExoticType),
  currency: tsEnum(CurrencyCode),
  accountHolderTypes: notEmpty(array(tsEnum(InternalAccountHolderType))),
  depositRequirement: object({
    min: refine(stringNumber(), 'depositRequirement.min', (value, ctx) => {
      if (!isNaN(parseFloat(ctx.branch[0].depositRequirement.max))) {
        return value < ctx.branch[0].depositRequirement.max;
      }
      return value >= 0;
    }),
    max: refine(stringNumber(), 'depositRequirement.max', (value, ctx) => {
      if (!isNaN(parseFloat(ctx.branch[0].depositRequirement.min))) {
        return value > ctx.branch[0].depositRequirement.min;
      }
      return value >= 0;
    }),
  }),
  interestFeature: object({
    accrualPeriod: optional(string()),
    payoutPeriod: tsEnum(InterestPayoutPeriodType),
    interestCutOffTime: string(), // TODO: validate time
  }),
  periodFeature: object({
    termPeriod: requiredWhen(
      nullable(refine(stringNumber(), 'termPeriod', (value) => value >= 1)),
      (_, ctx) => ctx.branch[0].productType === ProductType.TERM
    ),
    noticePeriod: requiredWhen(
      nullable(stringNumber()),
      (_, ctx) => ctx.branch[0].productType === ProductType.NOTICE
    ),
    coolOffPeriod: tsEnum(CoolOffPeriodType),
  }),
  productLiterature: optional(
    object({
      tandcUrl: nullable(optional(string())),
      brochureUrl: nullable(optional(string())),
      infoUrl: nullable(optional(string())),
    })
  ),
  holidayCalendar: notEmpty(string()),
  taxWrappers: notEmpty(array(tsEnum(InternalTaxWrapper))),
  productAvailability: object({
    availableFrom: minSize(string(), 1), // TODO: validate date
    availableUntil: nullable(string()), // TODO: validate date
  }),
  maximumAvailable: refine(
    stringNumber(),
    'maximumAvailable',
    (value) => value >= 1
  ),
  startDate: optional(string()),
  stopDisplayAt: nullable(optional(string())),
  accountDetails: any(),
  isTracker: enums(['true', 'false']),
});

export const AccountDetailsValidation = (
  accountNumRef?: RefObject<string | undefined>,
  sortCodeRef?: RefObject<string | undefined>
) =>
  object({
    accountDetails: object({
      sortCode: refine(
        size(string(), SORT_CODE_LENGTH, SORT_CODE_LENGTH),
        'sortcode',
        () => {
          return new UkModulusChecking({
            sortCode: sortCodeRef?.current ?? '',
            accountNumber: accountNumRef?.current ?? '',
          }).isValid();
        }
      ),
      accountNumber: refine(
        size(string(), ACCOUNT_NUMBER_LENGTH, ACCOUNT_NUMBER_LENGTH),
        'accountNumber',
        () => {
          return new UkModulusChecking({
            sortCode: sortCodeRef?.current ?? '',
            accountNumber: accountNumRef?.current ?? '',
          }).isValid();
        }
      ),
      stopDisplayAt: optional(string()),
      accountName: optional(string()),
    }),
  });

const AdditionalAddProductValidation = object({
  isCompositeProduct: enums(['true', 'false']),
  bankUid: notEmpty(bankUidString()),
  taxWrappers: notEmpty(array(tsEnum(InternalTaxWrapper))),
});

const RateDetailAndFeeDetailValidation = object({
  rateDetail: object({
    grossRate: stringPercentageNumber(),
    startDate: optional(string()),
    // startDate: refine(string(), 'invalidDate', (value, ctx) => {
    //   console.log(value);
    //   return dateRegex.test(value);
    // }),
    announcedAt: optional(string()),
  }),
});

const ChangeEffectiveFromValidation = object({
  changeEffectiveFrom: notEmpty(string()),
});

const StartDateValidation = object({
  startDate: nullable(optional(string())),
});

export const AddProductValidationWithAccountDetails = (
  accountNumRef?: RefObject<string | undefined>,
  sortCodeRef?: RefObject<string | undefined>
) =>
  assign(
    ProductValidation,
    AdditionalAddProductValidation,
    RateDetailAndFeeDetailValidation,
    AccountDetailsValidation(accountNumRef, sortCodeRef)
  );

export const AddProductValidation = assign(
  ProductValidation,
  AdditionalAddProductValidation,
  RateDetailAndFeeDetailValidation
);

export const EditProductValidationWithAccountDetails = (
  accountNumRef: RefObject<string | undefined>,
  sortCodeRef: RefObject<string | undefined>
) =>
  assign(
    ProductValidation,
    ChangeEffectiveFromValidation,
    StartDateValidation,
    AccountDetailsValidation(accountNumRef, sortCodeRef)
  );

export const EditProductValidation = assign(
  ProductValidation,
  ChangeEffectiveFromValidation,
  StartDateValidation
);

export const ACCOUNT_NUMBER_LENGTH = 8;
export const SORT_CODE_LENGTH = 6;
